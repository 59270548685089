import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import GlobalWrapper from '../components/globalWrapper'

const Section = styled.section`
  min-height: calc(100vh - 321px);
  ${media.sm`
    min-height: calc(100vh - 439px);
  `}
`

const TermsOfUse = () => {
  return (
    <GlobalWrapper>
      <Seo title="Nutzungsbedingungen" />
      <Nav caps wMax="xl" withBorder />
      <Section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>Nutzungsbedingungen</h1>
            <p>
              Durch die Nutzung von stretchzeltbayern.de stimmen Sie den{' '}
              <a href="https://policies.google.com/terms?hl=de">
                Nutzungsbedingungen der Google Services
              </a>{' '}
              zu.
            </p>
            <p>
              Die Google Services werden zur Autovervollständigung von Adressen
              und der Anfahrtsberechnung in unserem Mietpreisrechner genutzt.
            </p>
          </Box>
        </Container>
      </Section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default TermsOfUse
